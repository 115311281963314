export function removeEmptyFields<T>(obj: T): Partial<T> {
  if (Array.isArray(obj)) {
    return obj
      .map((value) =>
        typeof value === 'object' && value != null
          ? removeEmptyFields(value)
          : value,
      )
      .filter(
        (value) =>
          !(
            value == null ||
            value === '' ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' &&
              value != null &&
              Object.keys(value).length === 0)
          ),
      ) as any;
  } else if (typeof obj === 'object' && obj != null) {
    const newObj = {} as Partial<T>;

    for (const [key, value] of Object.entries(obj)) {
      const cleanedValue =
        typeof value === 'object' && value != null
          ? removeEmptyFields(value)
          : value;

      if (
        cleanedValue != null &&
        cleanedValue !== '' &&
        !(Array.isArray(cleanedValue) && cleanedValue.length === 0) &&
        !(
          typeof cleanedValue === 'object' &&
          Object.keys(cleanedValue).length === 0
        )
      ) {
        newObj[key as keyof T] = cleanedValue;
      }
    }
    return newObj;
  }
  return obj;
}
